<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="heading mb_10">
              <h2>Email Templates</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-md-6 text-lg-end" v-show="editPermission">
            <router-link :to="{ name: 'add_new_template' }" class="btn btn_primary">Add New Template
            </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">

              <div class="table_card">
                <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                  <div class="dataTables_length" id="datatable_length">
                    <label>Show
                      <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                        @change="getProductsBysearch">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                        <option>500</option>
                      </select>
                      entries</label>
                  </div>
                  <div id="datatable_filter" class="dataTables_filter">
                    <label><input type="search" class="" placeholder="Search" aria-controls="datatable"
                        v-model="listConfig.search_string" v-on:keyup.enter="getProductsBysearch" />
                      <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getProductsBysearch" class="search_icon cntr_search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                          viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                      </a>
                      <a href="javascript:void(0)" v-if="listConfig.search_string"
                        @click="listConfig.search_string = null; getProductsBysearch()" class="crossicon cntr_cross">
                        X
                      </a>
                    </label>
                  </div>
                </div>
                <div class="table-responsive table_audit_log">
                  <table class="table table-bordered table dataTable no-footer">
                    <thead>
                      <tr>
                        <th class="sort">
                          <SortIcons :lable="'Template Name'" :listConfig="listConfig" :sortBy="'template_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                          <SortIcons :lable="'Subject'" :listConfig="listConfig" :sortBy="'subject'"
                            @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                          <SortIcons :lable="'Client'" :listConfig="listConfig" :sortBy="'company_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" v-show="actionSection">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(template, indx) in template_list" :key="indx">
                        <td style="width: 10% !important">
                          <p class="">
                            {{ template.template_name | capitalize }}
                          </p>
                        </td>
                        <td style="width: 10% !important">
                          {{ template.subject | capitalize }}
                        </td>
                        <td style="width: 10% !important">
                          {{ template.company_name }}
                        </td>
                        <td style="width: 10% !important" v-show="actionSection">
                          <div class="edit_btn">
                            <p>
                              <a href="javascript:void(0);" class="" v-show="viewPermission" title="View" @click="showEmailTemplate(template)" data-bs-toggle="modal" data-bs-target="#viewtemplate">
                                <img src="../../assets/images/dashboard/View_Icon.svg" alt="View Report" >
                              </a>
                              <router-link :to="{
                                  name: 'edit_new_template',
                                  params: { id: template.u_id },
                                }" class="ml_10" v-show="editPermission"
                                title="Edit">
                                <img
                                  class="edit-icon"
                                  src="../../assets/admin/images/Edit_Icon.svg"
                                  alt="img"
                                />
                              </router-link>
                              <a href="javascript:void(0);" class="ml_10" data-bs-toggle="modal"
                                :data-bs-target="
                                  '#duplicatetemp1' + template.id
                                " v-show="editPermission"
                                title="Duplicate">
                              <img
                                  class="edit-icon"
                                  src="../../assets/admin/images/copy-16.png"
                                  alt="img"
                                />  
                              </a>
                              <a href="javascript:void(0);" class="" data-bs-toggle="modal" :data-bs-target="'#deletetemp1' + template.id" v-show="deletePermission" title="Delete">
                                <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                style="margin-left: 10px; margin-top: -3px"
                                viewBox="0 0 13.503 14.94"
                              >
                                <path
                                  data-name="Path 108"
                                  d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                                  transform="translate(-3 -2)"
                                  style="
                                    fill: none;
                                    stroke: #999292;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 2px;
                                  "
                                ></path>
                              </svg>
                              </a>
                            </p>
                            <!-- Modal -->
                            <div class="modal user_box_up fade" :id="'duplicatetemp1' + template.id" tabindex="-1"
                              aria-labelledby="duplicatetemp1Label" aria-hidden="true">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="duplicatetemp1Label">
                                      Duplicate Template
                                    </h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                      aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                    Do you want to duplicate this template?
                                  </div>
                                  <div class="modal-footer">

                                    <button type="button" @click="
                                        duplicateEmailTemplate(template.u_id)
                                      " class="btn btn_primary" data-bs-dismiss="modal">
                                      Yes
                                    </button>
                                    <button type="button" class="btn btn_outline" data-bs-dismiss="modal">
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Modal -->
                          <!-- Modal -->
                          <div class="modal user_box_up fade" :id="'deletetemp1' + template.id" tabindex="-1"
                            aria-labelledby="deletetemp1Label" aria-hidden="true">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="deletetemp1Label">
                                    Delete Template
                                  </h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  Do you want to delete this template?
                                </div>
                                <div class="modal-footer">

                                  <button type="button" @click="deleteEmailTemplate(template.u_id)"
                                    class="btn btn_primary" data-bs-dismiss="modal">
                                    Yes
                                  </button>
                                  <button type="button" class="btn btn_outline" data-bs-dismiss="modal">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                            <!-- Modal -->
                          </div>
                        </td>
                      </tr>
                      <tr v-if="data_length==0">
                        <td colspan="7" class="text-center">No Records found</td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                  :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal user_box_up fade" id="viewtemplate" tabindex="-1" aria-labelledby="viewtempLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="viewtempLabel">
                  {{ templateDetail.subject }}
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body details_eval">
                <div  v-html="templateDetail.content">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
      </div>
    </main>
    <!-- </div>
            </div>
        </div> -->
  </div>
</template>

<script>
  import "vue-select/dist/vue-select.css";
  import VPagination from "@/components/VPagination";
  import SortIcons from "@/components/SortingIcons";
  import commonFunction from '../../mixin/commonFunction';

  export default {
    name: "Email_template",
    mixins: [commonFunction],
    components: {
      VPagination,
      SortIcons,
    },
    data() {
      return {
        template_list: [],
        currentPage: 1,
        itemsPerPage: 10,
        listConfig: {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          sortBy: "template_name",
          order: "ASC",
          search_string: null,
        },
        data_length: 0,
        template_id: {
          templateid: "",
        },
        editPermission: false,
        deletePermission: false,
        viewPermission:false,
        actionSection: true,
        templateDetail: [],
      };
    },
    computed: {
      positionText: function () {
        var endIndex = this.currentPage * this.itemsPerPage,
          startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
        if (this.data_length == 0) {
          startIndex = 0;
        }
        return (
          "Showing " +
          startIndex +
          " to " +
          (endIndex > this.data_length ? this.data_length : endIndex) +
          " of " +
          this.data_length
        );
      },
      pages() {
        if (this.data_length <= this.itemsPerPage) {
          return [1];
        }
        let pages_array = [
          ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
        ].map((e) => e + 1);
        return pages_array;
      },
    },
    watch: {
      currentPage(newValue) {
        this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
        this.getEmailTemplateList();
      },
    },
    mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      if (this.$route.params.successMsg) {
        this.$toast.success(this.$route.params.successMsg, {
          position: "top-right",
          duration: 5000,
        });
      }
      if (this.$route.params.errorMsg) {
        this.$toast.error(this.$route.params.errorMsg, {
          position: "top-right",
          duration: 5000,
        });
      }
      var _this = this;
      this.listConfig = {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "template_name",
        order: "ASC",
        search_string: null,
      };
      _this.getEmailTemplateList();
    },
    beforeMount() {
      // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      if (localStorage.getItem("userToken")) {
        window.addEventListener("scroll", this.scrollvue);
        // Store for check permission
        let res = this.$store.dispatch("auth/getAuthUser");
        res
          .then((value) => {
            let allPermission = value.user.roles.all_permission;
            this.editPermission = allPermission.includes("email-templates-edit");
            this.deletePermission = allPermission.includes(
              "email-templates-delete"
            );
            this.viewPermission = allPermission.includes('email-templates-view');
            if (!this.editPermission && !this.deletePermission && !this.viewPermission) {
              this.actionSection = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // this.logout();
      }
    },
    methods: {
      onPageChange(page) {
        this.currentPage = page;
      },
      sorting($event) {
        this.listConfig.sortBy == $event.sortBy;
        this.listConfig.order == $event.order;
        this.getEmailTemplateList();
      },
      getProductsBysearch() {
        this.currentPage = 1;
        this.listConfig.limit = this.itemsPerPage;
        this.getEmailTemplateList();
      },
      getEmailTemplateList() {
        var _this = this;
        if (localStorage.getItem("userToken")) {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/get-templates-paginations",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
            },
            data: {request_data : this.encodeAPIRequest(_this.listConfig)},
          };
          _this
            .axios(config)
            .then(({
              data
            }) => {
              var decodedData = _this.decodeAPIResponse(data.data);
              _this.template_list = decodedData.templates;
              _this.data_length = decodedData.counts;
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({
              response
            }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (response.status == 500) {
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              }
              if (response.data.type == false) {
                _this.tokenExpired();
              }
            });
        } else {
          _this.tokenExpired();
        }
      },
      duplicateEmailTemplate(templateId) {
        var _this = this;
        _this.template_id.templateid = templateId;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/duplicate-template",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.template_id)},
        };
        _this
          .axios(config)
          .then(({
            data
          }) => {
            _this.$toast.success(
              data.message, {
                position: "top-right",
                duration: 5000,
              }
            );
            _this.getEmailTemplateList();
          })
          .catch(({
            response
          }) => {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          });
      },
      deleteEmailTemplate(templateId) {
        var _this = this;
        let config = {
          method: "delete",
          url: process.env.VUE_APP_API_URL + "/api/delete-template/" + templateId,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        };
        _this
          .axios(config)
          .then(({
            data
          }) => {
            _this.$toast.success(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.getEmailTemplateList();
          })
          .catch(({
            response
          }) => {
            console.log("Error", response.data.error);
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          });
      },
      showEmailTemplate(template){
        this.templateDetail = template;
      }
      // dataTableWithData() {
      //   $("#datatable").DataTable({
      //     bDestroy: true,
      //     aoColumnDefs: [
      //       {
      //         bSortable: false,
      //         aTargets: [-1],
      //       },
      //     ],
      //   });
      // },
    },
  };
</script>